import { ConfigProvider } from 'antd';
import styled, { ThemeProvider } from 'styled-components';

import Header from 'components/Header';
import Footer from 'components/Footer';

import GlobalStyled from 'styles/globalStyled';
import { theme } from 'theme';
import {
  useEffect,
  useState
} from 'react';
import PageLoading from 'components/PageLoading';

const LayoutStyled = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Content = styled.main`
  flex: 1;
`;

const Layout = (props) => {
  const { children } = props;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <LayoutStyled>
      <ThemeProvider theme={theme}>
        <ConfigProvider
          theme={{ token: theme }}
        >
          <GlobalStyled />

          {loading ? (
            <PageLoading />
          ) : (
            <>
              <Header />

              <Content>
                {children}
              </Content>

              <Footer />
            </>
          )}
        </ConfigProvider>
      </ThemeProvider>
    </LayoutStyled>
  );
};

export default Layout;
