import { createGlobalStyle } from 'styled-components';
import './normalize.css';

const GlobalStyled = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }
  
  h1 {
    font-family: 'Roboto', 'sans-serif';
    font-weight: 900;
    font-size: 1.8rem;
    line-height: 2.2rem;
    margin: 0.85rem 0;
  }

  h2 {
    font-family: 'Open Sans', 'sans-serif';
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 0.8rem 0;
  }

  h3 {
    font-family: 'Open Sans', 'sans-serif';
    font-weight: 300;
    font-size: 1.3rem;
    line-height: 2rem;
    margin: 0.75rem 0;
  }

  body, p {
    font-family: 'Open Sans', 'sans-serif';
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: ${({ theme }) => theme.colorTextBase};
  }
  
  .ant-form-item-label {
    padding-bottom: 0 !important;
  }
  
  .ant-card .ant-card-meta-description {
    color: ${({ theme }) => theme.colorTextSecondary};
  }
  
  img,
  video {
    max-width: 100%;
  }
`;

export default GlobalStyled;
