import {
  Col,
  Row
} from 'antd';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { darken } from 'polished';

import Logo from 'components/Logo';
import Wrapper from 'components/Wrapper';
import { termsUrl } from 'urls';
import config from 'config';

const Section = styled.div`
  padding-bottom: 1rem;
  
  background-color: ${({ theme }) => theme.footerBgColor};
  color: ${({ theme }) => theme.footerTextColor};
  
  a {
    color: ${({ theme }) => theme.footerTextColor};
    &:hover {
      color: ${({ theme }) => darken(0.2, theme.footerTextColor)};
    }
  }
`;

const Title = styled.div`
  font-size: 1.1rem;
  margin: 1rem 0 .4rem;
`;

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  
  & > li {
    &:before {
      content: '-';
      margin-right: 0.2rem;
    }
  }
`;

const Footer = () => {
  const { siteName } = config;

  const links = [
    {
      to: termsUrl,
      name: 'Polityka prywatności'
    }
  ];

  return (
    <Section>
      <Wrapper>
        <Row
          justify='space-between'
        >
          <Col>
            <Title>
              Informacje:
            </Title>

            <List>
              {links.map((link) => (
                <li
                  key={link.to}
                >
                  <Link
                    to={link.to}
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
            </List>
          </Col>

          <Col style={{ textAlign: 'right' }}>
            <Logo />

            <div>
              Wszelkie prawa zastrzeżone
            </div>

            <div>
              {siteName} &copy; {new Date().getFullYear()}
            </div>
          </Col>
        </Row>
      </Wrapper>
    </Section>
  );
};

export default Footer;
