const config = {
  apiUrl: process.env.API_URL,
  recaptchaKey: process.env.RECAPTCHA_PUBLIC_KEY,

  siteName: 'Homeeer',
  description: 'Homeeer to platforma pozwalająca Ci na indywidualne kształtowanie elementów budowlanych projektu '
    + 'w oparciu o dwa warianty - Ekologiczny, Energooszczędny.'
};

export default config;
