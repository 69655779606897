import { useTheme } from 'styled-components';

import Wrapper from 'components/Wrapper';
import Logo from 'components/Logo';

const Header = () => {
  const theme = useTheme();

  return (
    <div style={{ background: theme.headerBgColor }}>
      <Wrapper>
        <Logo />
      </Wrapper>
    </div>
  );
};

export default Header;
