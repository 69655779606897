export const theme = {
  // ant design seed
  fontFamily: 'Open Sans, sans-serif',

  colorPrimary: '#578230', // accent color

  colorSuccess: '#578230',
  colorWarning: '#fa8c16',
  colorErrors: '#e4672f',

  colorTextBase: '#333333',
  colorTextSecondary: 'rgba(51, 51, 51, 0.68)',
  colorBgBase: '#fff',
  colorBgContainer: '#fff',

  colorLink: '#0063B1',

  colorShadowPrimary: 'rgba(31, 45, 3, 0.29)',
  colorBorderPrimary: '#6e8f4c',

  borderRadius: 6,
  boxShadow: '0 0 15px 0 rgba(0, 0, 0, 0.15)',

  // extended
  headerTextColor: '#fff',
  headerBgColor: '#3b3c3e',

  footerTextColor: '#fff',
  footerBgColor: '#3b3c3e'
};

export const size = {
  sm: '576px',
  md: '768px',
  lg: '991px',
  xl: '1200px',
  xxl: '1600px'
};

export const mediaPredicate = {
  xs: `screen and (max-width: ${size.sm})`,
  sm: `screen and (min-width: ${size.sm})`,
  md: `screen and (min-width: ${size.md})`,
  lg: `screen and (min-width: ${size.lg})`,
  xl: `screen and (min-width: ${size.xl})`,
  xxl: `screen and (min-width: ${size.xxl})`
};

export const mediaQuery = {
  xs: `@media ${mediaPredicate.sm}`,
  sm: `@media ${mediaPredicate.sm}`,
  md: `@media ${mediaPredicate.md}`,
  lg: `@media ${mediaPredicate.lg}`,
  xl: `@media ${mediaPredicate.xl}`,
  xxl: `@media ${mediaPredicate.xxl}`
};
