import styled, { keyframes } from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import config from 'config';

const Wrapper = styled.div`
  display: flex;

  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  flex: auto;
`;

const LoadingRotate = keyframes`
  0% {
    transform: rotate(0deg);
  }

  60% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

const LogoWrapper = styled.div`
  display: block;
  width: 40px;
  height: 40px;

  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  transform-origin: center;

  animation-duration: 1.5s;
  animation-name: ${LoadingRotate};
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.98, 0.36, 0.5, 1.39);
  animation-delay: 0.5s;
  animation-direction: normal;
`;

const PageLoading = (props) => {
  const { className } = props;

  return (
    <Wrapper className={className}>
      <LogoWrapper>
        <StaticImage
          alt={config.siteName}
          src='../../images/logo.png'
          placeholder='blured'
        />
      </LogoWrapper>
    </Wrapper>
  );
};

export default PageLoading;
