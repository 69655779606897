import styled from 'styled-components';

const Wrapper = styled.div`
  box-sizing: border-box;
  max-width: ${(props) => props.maxWidth || '1440px'};
  width: 100%;
  padding: ${({ padding }) => padding ?? '0 2rem'};
  margin: 0 auto;
  background-color: ${({ bgColor }) => bgColor ?? 'transparent'};
`;

export default Wrapper;
