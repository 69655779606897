import { Link } from 'gatsby';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

import { homeUrl } from 'urls';
import config from 'config';

const LogoWrapper = styled(Link)`
  display: block;
  padding: 1rem 0;
`;

const Logo = (props) => {
  const { siteName } = config;

  return (
    <LogoWrapper
      to={homeUrl}
      title={siteName}
      {...props}
    >
      <StaticImage
        src='../../images/logo.png'
        alt={siteName}
        style={{ maxWidth: '40px' }}
        placeholder='none'
      />
    </LogoWrapper>
  );
};

export default Logo;
